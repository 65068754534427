import { useTranslate } from '/machinery/I18n'

import { ContainerLg } from '/features/buildingBlocks/Container'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { PortableTextMainContent } from '/features/buildingBlocks/PortableText'
import { Pills } from '/features/pageOnly/traineeship/Pills'
import { ButtonLinkPrimary } from '/features/buildingBlocks/Button'
import ImageCover from '/features/buildingBlocks/ImageCover.universal'

import styles from './CtaToTraineeship.css'

export function CtaToTraineeship({ openCloseStatus, image, title, blocks, pills, link, subtitle }) {
  return (
    <div data-style-context='blue' className={styles.component}>
      <ContainerLg>
        <div className={styles.inner}>
          {image && <ImageCover layoutClassName={styles.imageLayout} aspectRatio={1 / 1} {...{ image }}  />}

          <div data-style-context='light' className={styles.content}>
            {title && <HeadingMd layoutClassName={styles.headingLayout} {...{ title }} h={3} />}
            {Boolean(blocks?.length) && <PortableTextMainContent layoutClassName={styles.textLayout} value={blocks} />}
            {Boolean(pills) && <Pills {... { pills }} />}
            <Bottom layoutClassName={styles.bottomLayout} {...{ subtitle, link, openCloseStatus }} />
          </div>
        </div>
      </ContainerLg>
    </div>
  )
}

function Bottom({ subtitle, link, openCloseStatus, layoutClassName }) {
  const { __ } = useTranslate()
  return (
    <div className={cx(styles.componentBottom, layoutClassName)}>
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      {Boolean(link?.href && link?.label) && openCloseStatus !== 'closed'
        ? <ButtonLinkPrimary dataX='link-to-traineeship' label={link.label} href={link.href} />
        : <p className={styles.subText}>{__`registration-closed`}</p>
      }
    </div>
  )
}
