import { useScrollProgression, triggers } from '@kaliber/scroll-progression'
import { animated, useSpring, useSpringValue } from 'react-spring'
import { lerp } from '@kaliber/math'

import colors from '/cssGlobal/color.css'
import styles from './QuoteWithText.css'

export function QuoteWithText({ quote, name }) {
  const progress = useSpringValue(-1, {
    config: { tension: 500, friction: 35 }
  })

  const ref = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.custom(0.75) },
    end: { element: triggers.top(), scrollParent: triggers.center(0.25) },
    onChange(x) {
      progress.start(lerp({ start: -1, end: 100, input: easeOut(x) }))
    },
  })

  return (
    <figure {...{ ref }} className={styles.component}>
      <Blockquote {...{ quote, progress }} />
      <Name visible={progress.to(x => Math.floor(x))} layoutClassName={styles.nameLayout} {...{ name }} />
    </figure>
  )
}

function easeOut(x) {
  return Math.sin((x * Math.PI) / 2)
}

function Blockquote({ quote, progress }) {
  return (
    <animated.blockquote className={styles.componentBlockquote} style={{ '--pseudo-quote-color': getQuoteColor() }} >
      <animated.span className={styles.quoteHighlightElement} style={{ '--progress': progress }}>
        {quote}<span className={styles.nonSelectableQuote}>&quot;</span>
      </animated.span>
    </animated.blockquote>
  )

  function getQuoteColor() {
    return progress.to(x => x > 0
      ? colors.colorBlue500
      : colors.colorGray200
    )
  }
}

function Name({ name, layoutClassName, visible }) {
  const spring = useSpring({
    config: { tension: 300, friction: 35 },
    opacity: visible ? 1 : 0,
    y: visible ? 0 : 30,
  })

  return (
    <animated.figcaption className={cx(styles.componentName, layoutClassName)} style={spring}>
      {name}
    </animated.figcaption>
  )
}
