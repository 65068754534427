import { useMediaQuery } from '@kaliber/use-media-query'

// import { MobileFullscreenVideo } from '/features/pageOnly/wavemakers/MobileFullscreenVideo'
import { VideoWavemakers } from '/features/buildingBlocks/Video'

import mediaStyles from '/cssGlobal/media.css'

export function WavemakersVideoSelector({ data }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  // return !isViewportMd ? (
  //   <MobileFullscreenVideo
  //     title={data?.title}
  //     url={data?.videoHlsUrl}
  //     poster={data?.mobilePoster}
  //     playButtonHint={data?.playButtonHint}
  //     mobilePreviewHlsUrl={data?.mobilePreviewHlsUrl}
  //     desktopPreviewHlsUrl={data?.desktopPreviewHlsUrl}
  //   />
  // ) : (
  //   <VideoWavemakers
  //     title={data?.title}
  //     mobilePoster={data?.mobilePoster}
  //     desktopPoster={data?.desktopPoster}
  //     playButtonHint={data?.playButtonHint}
  //     preview={isViewportMd ? data?.desktopPreviewHlsUrl : data?.mobilePreviewHlsUrl}
  //     url={data?.src}
  //   />
  // )

  return (
    <VideoWavemakers
      title={data?.title}
      mobilePoster={data?.mobilePoster}
      desktopPoster={data?.desktopPoster}
      playButtonHint={data?.playButtonHint}
      preview={isViewportMd ? data?.desktopPreviewHlsUrl : data?.mobilePreviewHlsUrl}
      url={data?.src}
    />
  )
}
