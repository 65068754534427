import { resolveLinkObjectToHref } from '/machinery/link'

import { HeadingMd } from './Heading'
import { ButtonLinkPrimary, ButtonLinkWithIcon } from './Button'

import styles from './CtaWithTitleAndButton.css'

export function CtaWithTitleAndButton({ title, link }) {
  return (
    <CtaWithTitleAndButtonBase
      className={styles.component}
      Button={ButtonLinkPrimary}
      {...{ title, link }}
    />
  )
}

export function CtaWithTitleAndButtonTechblog({ title, link }) {
  return (
    <CtaWithTitleAndButtonBase
      styleContext='blue'
      className={styles.componentTechblog}
      Button={ButtonLinkWithIcon}
      {...{ title, link }}
    />
  )
}

function CtaWithTitleAndButtonBase({ title, link, Button, styleContext = undefined, className = undefined }) {
  const href = resolveLinkObjectToHref(link)

  return (
    <div className={cx(styles.componentBase, className)} data-style-context={styleContext}>
      {title && <HeadingMd layoutClassName={styles.headingLayout} h={3} {...{ title }} />}
      {href && (
        <Button
          label={link.label}
          dataX='link'
          targetSelf={link?.targetSelf}
          {...{ href }}
        />
      )}
    </div>
  )
}
