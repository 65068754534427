let videoPreviewOpenCallback = null
let onVideoPreviewStateChangedCallback = null

export function setOnVideoPreviewOpen({ callback }) {
  videoPreviewOpenCallback = callback
}

export function useVideoPreviewStateChanged() {
  return React.useCallback(
    ({ open }) => {
      onVideoPreviewStateChangedCallback({ open })
    },
    []
  )
}

export function useOpenVideoPreview() {
  return React.useCallback(
    x => { videoPreviewOpenCallback?.(x) },
    []
  )
}
