import { FaqWrapper, FaqItem } from '@kaliber/rabobank-components'
import { PortableTextMainContent } from '/features/buildingBlocks/PortableText'

import styles from './FaqList.css'

export function FaqList({ questions }) {
  return (
    <FaqWrapper>
      {questions.map((question, i) => (
        <FaqItem key={question._key || i} summary={question.question}>
          <div className={styles.answer}>
            {question.blocks.map((block, i) => (
              <div key={i} className={styles.answerSection}>
                <PortableTextMainContent value={block} />
              </div>
            ))}
          </div>
        </FaqItem>
      ))}
    </FaqWrapper>
  )
}
