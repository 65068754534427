import { HeadingMd } from './Heading'
import { useTranslate } from '/machinery/I18n'
import { ShowSkillsMatchPortalFlowButton } from '/features/pageOnly/skillsMatch/buildingBlocks/ShowSkillsMatchPortalFlowButton'

import styles from './SkillsBanner.css'

import image from '/images/janineVos.png'

export function SkillsBanner() {
  const { __ } = useTranslate()

  return (
    <div data-style-context='light-alt' className={styles.component}>
      <div className={styles.content}>
        <div className={styles.heading}>
          <HeadingMd title={__`rabo-skills-title`} h={4} />
        </div>
        <p className={styles.text}>
          {__`explain-rabo-skills`}
        </p>
        <div className={styles.button}>
          <ShowSkillsMatchPortalFlowButton label={__`rabo-skills-cta`} buttonType='primary' dataX='link-cta-to-skillsmatchportal' />
        </div>
      </div>
      <div className={styles.image}>
        <img src={image} alt='Janine Vos' />
      </div>
    </div>
  )
}
