import { HeadingMd } from './Heading'
import { PortableTextMainContent } from '/features/buildingBlocks//PortableText'
import { Icon } from '/features/buildingBlocks/Icon'
import { useElementSize } from '@kaliber/use-element-size'

import chevronIcon from '/images/icons/chevron-top.raw.svg'
import styles from './Faqs.css'

export function FaqsTwoColumns({ title, description, questions }) {
  return (
    <Base className={styles.componentTwoColumns} {... { questions }}>
      <TwoColumnContent {...{ title, description }} />
    </Base>
  )
}

export function FaqsOneColumn({ title, description, questions }) {
  return (
    <Base className={styles.componentOneColumn} {... { questions }}>
      <OneColumnContent {...{ title, description }} />
    </Base>
  )
}

function Base({ questions, className, children }) {
  return (
    <div className={cx(className, styles.componentBase)}>
      {children}
      <div className={styles.faqs}>
        {questions?.map(({ question, blocks, _key }) => (
          <Faq key={_key} answer={blocks} {... { question }} />
        ))}
      </div>
    </div>
  )
}

function TwoColumnContent({ description, title }) {
  return (
    <div className={styles.componentTwoColumnContent}>
      <ColumnContent {...{ description, title }} />
    </div>
  )
}

function OneColumnContent({ description, title }) {
  return (
    <>
      <ColumnContent {...{ description, title }} />
    </>
  )
}

function ColumnContent({ description, title }) {
  return (
    <div className={styles.componentColumnContent}>
      <div className={styles.heading}>
        <HeadingMd h={3} {... { title }} />
      </div>
      {description && <PortableTextMainContent value={description} />}
    </div>
  )
}

function Faq({ question, answer }) {
  const { size: { height }, ref: elementRef } = useElementSize()
  const [expanded, setExpanded] = React.useState(false)
  const [hover, setHover ] = React.useState(false)
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      aria-expanded={expanded}
      className={styles.componentFaq}
    >
      <div className={styles.header}>
        <h4 className={cx(styles.question, expanded && styles.active)}>
          <button
            aria-label={question}
            className={styles.button}
            type='button'
            data-x='click-to-open-faq'
            onClick={() => setExpanded(!expanded)}
          >
            {question}
          </button>
        </h4>

        <div className={cx(styles.icon, expanded && styles.rotate, hover && styles.fill)}>
          <span className={styles.chevron}><Icon icon={chevronIcon} /></span>
        </div>
      </div>
      <div style={{ height: expanded ? height + 16 + 'px' : 0 }} className={styles.containerAnswer}>
        <div ref={elementRef}><PortableTextMainContent value={answer} /></div>
      </div>
    </div>
  )
}
