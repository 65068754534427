import { resolveLinkObjectToHref } from '/machinery/link'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { ButtonLinkTertiary } from '/features/buildingBlocks/Button'
import ImageCover from '/features/buildingBlocks/ImageCover.universal'

import styles from './Cta.css'

export function Cta({ image, title, label, link }) {
  const href = resolveLinkObjectToHref(link)

  return (
    <div data-style-context='light-alt' className={styles.component}>
      <div className={styles.content}>
        {title && <HeadingMd layoutClassName={styles.headingLayout} h={3} {... { title }} />}
        <div className={styles.container}>
          {label && <p className={styles.label}>{label}</p>}
          {href &&
            <ButtonLinkTertiary
              label={link.label}
              dataX='link'
              targetSelf={link?.targetSelf}
              {...{ href }}
            />}
        </div>
      </div>
      {image && <div className={styles.image}>
        <ImageCover aspectRatio={1 / 1} {... { image }} />
      </div>}
    </div>
  )
}
