import { useTranslate } from '/machinery/I18n'
import { Employees } from '/features/buildingBlocks/Employees'
import { SocialShareAuthor } from '/features/buildingBlocks/SocialShare'

import styles from './EmployeeBiographies.css'

export function EmployeeBiographies({ employees }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.component}>
      <div className={styles.header}>
        <h2 className={styles.heading}>{__(employees.length)`about-the-authors`}</h2>
      </div>
      <div className={styles.biographies}>
        {employees.map((employee, i) => (
          <Biography key={i} {...{ employee }} />
        ))}
      </div>
    </div>
  )
}

function Biography({ employee }) {
  const { github, linkedIn, webLink } = employee
  const links = [
    ['github', github],
    ['linkedin', linkedIn],
    ['weblink', webLink]
  ].filter(([, value]) => !!value)

  return (
    <div className={styles.componentBiography}>
      <div className={styles.summary}>
        <Employees employees={[employee]} showMobilePortraits />
        {Boolean(links.length) && (
          <SocialShareAuthor {...{ links }} />
        )}
      </div>
      <div className={styles.biography}>{employee.biography}</div>
    </div>
  )
}
