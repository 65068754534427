import { Icon } from '/features/buildingBlocks/Icon'
import { useTranslate } from '/machinery/I18n'

import clockIcon from '/images/icons/clock.raw.svg'
import userIcon from '/images/icons/user.raw.svg'
import locationIcon from '/images/icons/location.raw.svg'
import euroIcon from '/images/icons/euro.raw.svg'

import styles from './Pills.css'

export function Pills({ pills, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  const {
    duration = undefined,
    startingSpots = undefined,
    departments = undefined,
    location = undefined,
    salary = undefined,
    hours = undefined,
    customProperties = []
  } = pills

  const valueAndIcon =  [
    { value: `${duration} ${__`months`}`, icon: clockIcon },
    { value: `${startingSpots} ${__`starting-spots`}`, icon: userIcon },
    { value: `${departments} ${departments === 1 ? __`department` : __`departments`}`, icon: clockIcon },
    { value: location, icon: locationIcon },
    { value: salary, icon: euroIcon },
    { value: hours, icon: clockIcon },
  ].filter(({ value }) => Boolean(value))

  const customPropertiesWithoutIcons = (customProperties || [])
    .filter(x => Boolean(x?.property))
    .map(x => ({ value: x?.property }))

  const allPillsTogether = [...valueAndIcon, ...customPropertiesWithoutIcons]

  return (
    <ul className={cx(styles.component, layoutClassName)}>
      {allPillsTogether?.map(({ value, icon }, i) => (
        <li className={styles.item} key={i}>
          {icon && <Icon {...{ icon }} />}
          {value && <span>{value}</span>}
        </li>
      ))}
    </ul>
  )
}
