import { HeadingSm } from '/features/buildingBlocks/Heading'
import ImageCover from '/features/buildingBlocks/ImageCover.universal'
import { SocialContact } from './SocialContact'

import iconLinkedIn from '/images/icons/linkedIn.raw.svg'
import iconEmail from '/images/icons/mail.raw.svg'
import iconWhatsApp from '/images/icons/whatsapp.raw.svg'

import styles from './CtaWithRecruiter.css'

export function CtaWithRecruiter({ title, recruiters }) {
  return (
    <div
      data-style-context='light'
      className={styles.component}
    >
      <div className={styles.content}>
        {title && <h3 className={styles.title}>{title}</h3>}
      </div>
      <Recruiters
        layoutClassName={styles.recruitersAndImageLayout}
        {... { recruiters }}
      />
    </div>
  )
}

function Recruiters({ recruiters, layoutClassName }) {
  return (
    <div className={cx(styles.componentRecruiters, layoutClassName)}>
      {recruiters.map(({ phone, email, linkedIn, photo, name }, i) => (
        <div key={i} className={styles.item}>
          <div className={styles.contentRecruiter}>
            {name && <HeadingSm layoutClassName={styles.nameLayout} title={name} h={4} />}
            <div className={styles.links}>
              {email && <SocialContact icon={iconEmail} href={`mailto:${email}`} dataX='link-to-recruiter-email' />}
              {phone && <SocialContact icon={iconWhatsApp} href={`https://wa.me/${phone}`} />}
              {linkedIn && <SocialContact icon={iconLinkedIn} href={`${linkedIn}`} />}
            </div>
          </div>
          <div className={styles.imageRecruiter}>
            {photo && <ImageCover aspectRatio={1 / 1} image={photo} />}
          </div>
        </div>
      ))}
    </div>
  )
}
