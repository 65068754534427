import { useLocation } from '@kaliber/routing'

import { routeMap } from '/routeMap'
import { useRouteData } from '/machinery/RouteData'
import { useClientConfig } from '/machinery/ClientConfig'
import { useLanguage, useTranslate } from '/machinery/I18n'

import { ContainerLg } from '/features/buildingBlocks/Container'
import { ContentTechblog } from '/features/buildingBlocks/Content'
import { HeadingXl } from '/features/buildingBlocks/Heading'
import { Employees } from '/features/buildingBlocks/Employees'
import { SocialSharePage } from '/features/buildingBlocks/SocialShare'
import { PortableTextIntro } from '/features/buildingBlocks/PortableText'
import { Swirl } from '/features/buildingBlocks/Swirl'
import PublishedDate from '/features/buildingBlocks/PublishedDate.universal'
import { LinkToTechblogsOverview } from '/features/pageOnly/techblog/LinkToTechblogsOverview'

import styles from './Techblog.css'

export const CONTENT_TYPE_BIOGRAPHIES = 'employeeBiographies'
export const CONTENT_TYPE_TAGS = 'tags'

export function Techblog() {
  const config = useClientConfig()
  const location = useLocation()
  const url = `${config.host}${location.pathname}`

  const { doc: techblog } = useRouteData()

  const {
    content: contentRaw,
    categories,
    title,
    employees,
    intro,
    publishDate: date,
    readingTime: readingTimeInMinutes,
    tags: tagsRaw,
  } = techblog

  const tags = tagsRaw?.map(({ title, slug }) => ({
    title,
    value: slug.current,
    key: 'tag'
  }))

  const content = [
    ...(contentRaw || []),
    (Boolean(tags?.length) && ({
      _type: CONTENT_TYPE_TAGS,
      tags
    })),
    (Boolean(employees?.length) && ({
      _type: CONTENT_TYPE_BIOGRAPHIES,
      employees
    }))
  ].filter(Boolean)

  return (
    <div className={styles.page}>
      <div className={styles.headerContent}>
        <Swirl layoutClassName={styles.swirlLayout} />

        <ContainerLg layoutClassName={styles.containerLayout}>
          <TechblogIntro {...{ categories, title, intro, employees, date, url, readingTimeInMinutes }} />
        </ContainerLg>
      </div>

      {content && <ContentTechblog {... { content }} />}
    </div>
  )
}

function TechblogIntro({ categories, title, intro, employees, date, url, readingTimeInMinutes }) {
  return (
    <div className={styles.componentIntro}>
      <header className={styles.introHeader}>
        <Breadcrumbs layoutClassName={styles.breadcrumbsLayout} {...{ categories }} />
        <HeadingXl h={1} layoutClassName={styles.headingLayout} {...{ title }} />
      </header>

      <div className={styles.introContainer}>
        <div className={styles.introText}>
          <PortableTextIntro value={intro} />
        </div>
        <div className={styles.metaData}>
          <MetaDataCard {...{ employees, date, url, title, readingTimeInMinutes }} />
        </div>
      </div>
    </div>
  )
}

function Breadcrumbs({ categories, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const language = useLanguage()

  const category = categories?.slice(0, 1)[0]

  return (
    <div className={cx(styles.componentBreadcrumbs, layoutClassName)}>
      <BreadcrumbLink
        href={routeMap.app.techblog({ language })}
        label={__`rabo-techblog`}
        dataX='link-to-techblogs-overview'
      />
      {category && (
        <>
          {'>'}
          <BreadcrumbLink
            href={`${routeMap.app.techblog.overview({ language })}?category[]=${category.slug.current}`}
            label={category.title}
            dataX='link-to-techblogs-overview-category'
          />
        </>
      )}
    </div>
  )
}

function BreadcrumbLink({ href, label, dataX }) {
  return <a data-x={dataX} className={styles.componentBreadcrumbLink} {...{ href }}>{label}</a>
}

function MetaDataCard({ employees, date, url, title, readingTimeInMinutes }) {
  const { __ } = useTranslate()

  return (
    <div data-style-context='light-alt' className={styles.componentMetaDataCard}>
      {Boolean(employees?.length) && (
        <div className={styles.employees}>
          <Employees showMobilePortraits {... { employees }} />
        </div>
      )}

      <div className={styles.metaDataWrapper}>
        <div>
          {readingTimeInMinutes && (
            <div className={styles.readingTime}>
              {__(Number(readingTimeInMinutes), { count: readingTimeInMinutes })`article-reading-time`}
            </div>
          )}
          <PublishedDate {...{ date }} />
        </div>
        <SocialSharePage {... { url, title }} />
      </div>

      <div className={styles.linkToOverviewContainer}>
        <LinkToTechblogsOverview />
      </div>
    </div>
  )
}
