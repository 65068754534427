/** @type {React.Context<object>} */
const routeDataContext = React.createContext({})

export function useRouteData() {
  return React.useContext(routeDataContext)
}

export function RouteDataContextProvider({ data, children }) {
  return <routeDataContext.Provider value={data} {...{ children }} />
}
