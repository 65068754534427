import { ButtonLinkWithIcon } from '/features/buildingBlocks/Button'
import { PortableTextMainContent } from './PortableText'

import VideoSmallWithoutOverlay from './VideoSmallWithoutOverlay.universal'
import VideoSmall from './VideoSmall.universal'

import styles from './TextWithVideo.css'

export function TextWithVideo({ text, video, hideOverlay, button, orientation = 'left' }) {
  const VideoElement = hideOverlay ? VideoSmallWithoutOverlay : VideoSmall

  return (
    <div className={cx(styles.component, styles[orientation])}>
      <div className={styles.video}>
        {video?.src && (
          <VideoElement
            url={video.src}
            title={video.title}
            poster={video.poster}
            layoutClassName={styles.videoLayout}
          />
        )}
      </div>

      <div className={styles.text}>
        <PortableTextMainContent value={text} />

        {button?.href && (
          <ButtonLinkWithIcon
            dataX='link'
            href={button.href}
            label={button.label}
            targetSelf={button.targetSelf}
            layoutClassName={styles.buttonLayout}
          />
        )}
      </div>
    </div>
  )
}
