import styles from './DefinitionList.css'

export function DefinitionList({ items }) {
  return (
    <dl>
      {items.map(({ title, content }, i) => (
        <div key={i} className={styles.item}>
          <dt className={styles.title}>{title}</dt>
          <dd>{content}</dd>
        </div>
      ))}
    </dl>
  )
}
