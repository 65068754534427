import { HeadingMd } from '/features/buildingBlocks/Heading'
import { PortableTextMainContent } from '/features/buildingBlocks/PortableText'

import styles from './IntroWithTitle.css'

export function IntroWithTitle({ title, introduction }) {
  return (
    <section data-x='section-intro-with-title' className={styles.component}>
      <HeadingMd h={2} layoutClassName={styles.titleLayout} {...{ title }} />
      <PortableTextMainContent value={introduction} layoutClassName={styles.introductionLayout} />
    </section>
  )
}
