import { routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { useRouteData } from '/machinery/RouteData'
import { navigateWithParams } from '/machinery/navigateWithParams'

import { PortableTextMainContent } from '/features/buildingBlocks/PortableText'
import { ButtonLinkPrimary } from '/features/buildingBlocks/Button'
import { HeadingMd } from '/features/buildingBlocks/Heading'

import styles from './WavemakersCta.css'

export function WavemakersCta({ title, content }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const { jobsData } = useRouteData()

  return (
    <div className={styles.component}>
      <div className={styles.container}>
        {title && <HeadingMd h={3} layoutClassName={styles.headingLayout} {...{ title }} />}
        {content && <PortableTextMainContent value={content} />}

        <ButtonLinkPrimary
          dataX='link-to-it-jobs'
          label={__({ count: jobsData?.totalHits })`wavemakers-cta-view-x-jobs`}
          href={navigateWithParams(routeMap.app.jobs({ language }), { job_branch: ['it'] })}
          layoutClassName={styles.buttonLayout}
        />
      </div>
    </div>
  )
}
