import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'

import { routeMap } from '/routeMap'

import { HeadingMd } from '/features/buildingBlocks/Heading'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './LinksBlock.css'

import iconChevron from '/images/icons/chevron.raw.svg'

export function LinksBlock({ title, links }) {
  return (
    <div>
      <div className={styles.heading}>
        <HeadingMd h={3} {... { title }} />
      </div>

      <ul className={styles.list} data-style-context="light-alt">
        {links?.map(({ title, description, link }, i) => (
          <li key={i}>
            <Link {...{ title, description, link }} />
          </li>
        ))}
      </ul>
    </div>
  )
}

function Link({ title, description, link }) {
  const [hover, setHover] = React.useState(false)

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={styles.componentLink}
    >
      <div>
        <h4 className={styles.heading}>{ title }</h4>
        <p className={styles.description}>{description}</p>
      </div>

      {link && (
        <a className={styles.link} href={determineDocumentPathSync({ document: link, routeMap })}>
          <span className={cx(styles.icon, hover && styles.fill)}>
            <Icon icon={iconChevron} />
          </span>
        </a>
      )}
    </div>
  )
}
