import Image from '/features/buildingBlocks/Image.universal'
import { ImageCaption } from '/features/buildingBlocks/ImageCaption'

import styles from './InlineImage.css'

export function InlineImage({ image, caption, sizes = undefined }) {
  return (
    <figure className={styles.component}>
      <div className={styles.imageContainer}>
        <Image {...{ image, sizes }} />
      </div>

      {caption && <ImageCaption layoutClassName={styles.captionLayout} {...{ caption }} />}
    </figure>
  )
}
