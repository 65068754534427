import ImageCover from '/features/buildingBlocks/ImageCover.universal'
import QuoteWithText from '/features/buildingBlocks/QuoteWithText.universal'

import styles from './QuoteWithImage.css'

export function QuoteWithImage({ quote, name, image }) {
  return (
    <div className={styles.component}>
      <div className={styles.content}>
        <QuoteWithText {...{ quote, name, image }} />
      </div>
      <div className={styles.image}>
        <ImageCover aspectRatio={1 / 1} {... { image }} />
      </div>
    </div>
  )
}
