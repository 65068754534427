import { routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'

import { LinkWithLabel } from '/features/buildingBlocks/Link'

export function LinkToTechblogsOverview() {
  const { __ } = useTranslate()
  const language = useLanguage()

  return (
    <LinkWithLabel href={routeMap.app.techblog({ language })} dataX='link-to-techblogs-overview'>
      {__`view-all-techblogs`}
    </LinkWithLabel>
  )
}
