import styles from './Podcast.css'

export function Podcast({ src, title }) {
  return (
    <iframe
      src={getCorrectSpotifyUrl(src)}
      width='100%'
      height='352'
      // @ts-ignore
      allowtransparency='true'
      allow='encrypted-media'
      className={styles.component}
      {...{ title }}
    />
  )
}

function getCorrectSpotifyUrl(src) {
  if (src.includes('spotify') && src.includes('episode')) {
    const pattern = /(.*)episode\/(.*)\?(.*)/sm
    const podcastId = src.replace(pattern, '$2')
    return podcastId ? `https://open.spotify.com/embed/episode/${podcastId}` : null
  }

  if (src.includes('spotify') && src.includes('playlist')) {
    const playlistId = src.split('/').pop().split('?')[0]
    const embedUrl = `https://open.spotify.com/embed/playlist/${playlistId}`
    return embedUrl
  }
}
