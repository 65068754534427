import { Icon } from '/features/buildingBlocks/Icon'

import arrowIcon from '/images/icons/arrow-down.raw.svg'
import styles from './Download.css'

export function Download({ title, file }) {
  const { url, size, extension } = file.asset
  return (
    <div className={styles.component}>
      <div className={styles.info}>
        {title && <strong className={styles.title}>{title}</strong>}
        <div className={styles.type}>{`${extension} - ${useFormattedValue(size)}`}</div>
      </div>
      <a
        data-x='download-file'
        href={url}
        target='_blank'
        rel="noreferrer"
        className={styles.icon}
        aria-label={title}
      >
        <Icon icon={arrowIcon} />
      </a>
    </div>
  )
  function useFormattedValue(value) {
    const fixedNumber = value / 1000
    const megaBytesValue = (fixedNumber / 1024)
    const number = megaBytesValue >= 1 ? megaBytesValue.toFixed(2) + 'MB' : fixedNumber + 'KB'
    return number
  }
}
