import ImageCover from '/features/buildingBlocks/ImageCover.universal'

import styles from './Employees.css'

export function Employees({ employees, layoutClassName = undefined, showMobilePortraits = false }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={cx(styles.images, showMobilePortraits && styles.showMobile)}>
        {employees.filter(x => x.avatar).map((x, i) => (
          <div key={`image__${x._key}__${i}`} className={styles.imageContainer}>
            <ImageCover aspectRatio={1 / 1} image={x.avatar} />
          </div>
        ))}
      </div>
      <div className={styles.names}>
        <div className={styles.name}>
          {employees.filter(x => x.title).map((x, i) => (
            <React.Fragment key={`employee__${x._key}__${i}`}>{i > 0 && ' & '}<span>{x.title}</span></React.Fragment>
          ))}
        </div>

        <div className={styles.functionTitle}>
          {keepUniqueValues(employees.filter(x => x.functionTitle).map(x => x.functionTitle)).join(' & ')}
        </div>
      </div>
    </div>
  )
}

function keepUniqueValues(array) {
  return Array.from(new Set(array))
}
