export function getSalaryRange({ locale, salaryCurrency, salaryMin, salaryMax }) {
  const intl = new Intl.NumberFormat(locale, { style: 'currency', currency: salaryCurrency, maximumFractionDigits: 0 })

  return `${intl.format(salaryMin)} - ${intl.format(salaryMax)}`
}

export function formatSalary(salary, { locale }) {
  const formatter = new Intl.NumberFormat(locale, { maximumFractionDigits: 0 })
  return formatter.format(salary)
}
