import { QuoteWithImage } from '/features/buildingBlocks/QuoteWithImage'
import { ContainerSm } from '/features/buildingBlocks/Container'
import QuoteWithText from '/features/buildingBlocks/QuoteWithText.universal'

import styles from './Quote.css'

export function Quote({ quote, name, image }) {
  return (
    image
      ? <QuoteWithImage {... { quote, name, image }} />
      : <ContainerSm><QuoteWithText {... { quote, name }} /></ContainerSm>
  )

}
